import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import akblogo from "../../images/logo-akebia-therapeutics.png"


// let data_domain_script;
// if (typeof window !== 'undefined') {
//   var domain_name = document.location.hostname;

//   if (domain_name == "auryxiahcp-staging.azurewebsites.net") {
//     data_domain_script = "58455c1a-7215-4353-8182-17bb00627ea9-test";
//   } else {
//     data_domain_script = "58455c1a-7215-4353-8182-17bb00627ea9";
//   }

// }
// console.log("the url: " + domain_name + ", " + data_domain_script);

// let data_domain_script;

const Footer = ({ siteTitle, siteAudience, jobCode, jobCodeDate, siteMapLink, url_hostname, data_domain_script }) => (

  <footer className={siteAudience} aria-label={siteTitle + " footer"} >
    <div className="flex-row">
      <div>
        <nav>
          <ul className="nav tertiary" aria-label="Auryxia Physician footer navigation">
            <li><a href="https://akebia.com/connect/" target="_blank" rel="noreferrer">Contact Us</a></li>
            <li><a href="https://akebia.com/termsofuse/" target="_blank" rel="noreferrer">Terms &amp; Conditions</a></li>
            <li><a href="https://akebia.com/privacy-policy/" target="_blank" rel="noreferrer">PRIVACY POLICY</a></li>
            <li><a href="https://akebia.com/" target="_blank" rel="noreferrer">akebia.com</a></li>
            {siteMapLink &&
              <li><Link to={siteMapLink}>SITE&nbsp;MAP</Link></li>
            }
          </ul>
        </nav>

        {/* <p>Akebia Therapeutics<sup>®</sup>, AkebiaCares<sup>®</sup> and their associated logos are trademarks of Akebia and/or its affiliates. &copy;{new Date().getFullYear()} Akebia Therapeutics, Inc. All rights reserved.
      <span>{jobCode}</span> {jobCodeDate}</p> */}
      <p>Akebia Therapeutics<sup>®</sup>, AkebiaCares<sup>®</sup> and their associated logos are trademarks of Akebia and/or its affiliates. &copy;2023 Akebia Therapeutics, Inc. All rights reserved.
      <span>{jobCode}</span> {jobCodeDate}</p>
      </div>

      <div>
        <img src={akblogo} alt="Akebia Therapeutics" />
      </div>
    </div>

    {<div
      dangerouslySetInnerHTML={{
        __html: `<!-- this is not working locally --> 
<!-- OneTrust Cookies Consent Notice start -->
<script src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script=${process.env.cookie_domain_script}></script>
<script type="text/javascript">
function OptanonWrapper() { }
</script>
<!-- OneTrust Cookies Consent Notice end -->
        `,
      }}>

    </div>}
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `AURYXIA® (ferric citrate) tablets`,
}

export default Footer
